
.questions-onboarding-wrapper{
    $animation-timing-function: $ease-out-apple1;
    &.first-animation{
        >.content{
            >.gr-left-side-bar{
                >div{
                    &:nth-child(1){
                        opacity: 0;
                        transform: translateX(calc(var(--side-margins)));
                        animation: $animationBaseTime * 2 from-opacity-transform $animation-timing-function $delay2 forwards;
                    }
                    &:nth-child(2){
                        opacity: 0;
                        transform: translateX(calc(var(--side-margins) * -1));
                        animation: $animationBaseTime * 2 from-opacity-transform $animation-timing-function $delay3-1 forwards;
                    }
                    &:nth-child(3){
                        >.pr-question-back-changer-wrapper{
                            >.wrapper{
                                >.arrow-wrapper{
                                    margin-left: -6vw;
                                    animation: $animationBaseTime from-margin $animation-timing-function $delay4 + .1s forwards;
                                }
                                >.question-number{
                                    opacity: 0;
                                    transform: translateX(calc(var(--side-margins) * -1));
                                    animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay4 forwards;
                                }
                            }
                            >p{
                                opacity: 0;
                                transform: translateX(calc(var(--side-margins) * -1));
                                animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay4 + .05s forwards;
                            }
                        }
                    }
                    &:nth-child(4){
                        opacity: 0;
                        transform: translateX(calc(var(--side-margins) * -1));
                        animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay5 forwards;
                    }
                }
            }
            >.gr-questions-list-wrapper{
                transform: scale(1.2) translateX(100px);
                animation: $animationBaseTime * 3 transform-layout $animation-timing-function $delay1 forwards;
                >.gr-questions-list{
                    >.gr-question-item{
                        &.active{
                            >.content-wrapper{
                                @import 'question-layout-1';
                            }
                        }
                    }
                }
            }
            >.header-bar{
                >.pr-x-mark-wrapper{
                    >.x-mark{
                        &:before{
                            width: 0%;
                            animation: $animationBaseTime to-width $animation-timing-function $delay5 forwards;
                        }
                        &:after{
                            height: 0%;
                            animation: $animationBaseTime to-height $animation-timing-function $delay6 forwards;
                        }
                    }
                }
            }
        }
        >.pr-arrow-submit-wrapper{
            >.hover-area{
                .arrow-submit-wrapper{
                    >svg >#static{
                        stroke-dasharray: 722;
                        stroke-dashoffset: 722;
                        animation: $animationBaseTime draw-svg-circle $animation-timing-function $delay4 forwards;
                    }
                    >span.counter{
                        opacity: 0;
                        transform: translateX(calc(var(--side-margins) * -1));
                        animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay5 forwards;
                    }
                }
            }
        }
        >.pr-progress-line-wrapper{
            opacity: 0;
            animation: $animationBaseTime from-opacity $animation-timing-function $delay5 forwards;
        }
        @media only screen and (min-width: $brake4 + 1px) and (min-height: $brake-vertical + 1px) {
            >.content{
                >.gr-left-side-bar{
                    width: 0;
                    margin-right: 0;
                    animation: $animationBaseTime * 3 show-left-side-bar $animation-timing-function $delay1 forwards;
                }
            }
        }
        @media only screen and (max-width: $brake4), only screen and (max-height: $brake-vertical) {
            >.content{
                >.gr-left-side-bar{
                    opacity: 0;
                    transform: translateY(var(--side-margins));
                    animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay1 forwards;
                }
                >.pr-backCTA-wrapper{
                    opacity: 0;
                    transform: translateX(var(--side-margins));
                    animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay2 forwards;
                }
                >.header-bar{
                    >.pr-logo-wrapper{
                        opacity: 0;
                        transform: translateY(var(--side-margins));
                        animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay1 forwards;
                    }
                    >.pr-x-mark-wrapper{
                        >.x-mark{
                            &:before{
                                animation-delay: $delay1;
                            }
                            &:after{
                                animation-delay: $delay2;
                            }
                        }
                    }
                }
            }
        }
    }
    //
    // keyframes
    //
    @keyframes from-opacity{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes from-top{
        0%{
            transform: translateY(calc(var(--side-margins) * -1));
        }
        100%{
            transform: translateY(0px);
        }
    }
    @keyframes from-opacity-transform{
        100%{
            opacity: 1;
            transform: translateY(0px);
        }
    }
    @keyframes from-margin{
        100%{
            margin: 0px;
        }
    }
    @keyframes to-width{
        100%{
            width: 100%;
        }
    }
    @keyframes to-height{
        100%{
            height: 100%;
        }
    }
    @keyframes draw-svg-circle{
        100%{
            stroke-dashoffset: 0;
        }
    }

    @keyframes show-left-side-bar{
        100%{
            width: var(--left-side-bar-width);
            margin-right: var(--left-side-bar-spacing);
        }
    }
    @keyframes show-left-side-bar-mobile{
        100%{
            width: 100%;
            margin-right: 0;
        }
    }
    @keyframes transform-layout{
        100%{
            transform: scale(1) translateX(0px);
        }
    }
}