$font-base: 18px;
$line-heigh:1.66;
$baseline: 30px;
$subdiv: 5px;
$gutter: 30px;
$side-margins: 60px;
$columns: 12;
$column-width: 85px;
$column1: 85px;
$column2: 200px;
$column3: 315px;
$column4: 430px;
$column5: 545px;
$column6: 660px;
$column7: 775px;
$column8: 890px;
$column9: 1005px;
$column10: 1120px;
$column11: 1235px;
$column12: 1350px;

$spacer1: 30px;
$spacer2: 60px;
$spacer3: 90px;
$spacer4: 120px;
$spacer5: 150px;
$spacer6: 180px;

