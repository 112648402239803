
.layout-1{
    $answers-limit: 40;
    >.title-wrapper{
        opacity: 0;
        animation: $animationBaseTime * 3 from-opacity $animation-timing-function $delay3 forwards;
    }
    >.content-wrapper{
        >.list-wrapper{
            &:first-child{
                >.sub-title{
                    opacity: 0;
                    animation: $animationBaseTime * 3 from-opacity $animation-timing-function $delay4 forwards;
                }
                >ul{
                    @for $index from 1 to $answers-limit {
                        >li:nth-of-type(#{$index}){
                            $delay: $delay4 + 0.08s * $index;
                            opacity: 0;
                            transform: translateX(calc(var(--side-margins) * -1));
                            animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay forwards;
                        }
                    }
                }
            }
            &:last-child{
                >.sub-title{
                    opacity: 0;
                    animation: $animationBaseTime * 3 from-opacity $animation-timing-function $delay4 forwards;
                }
                >ul{
                    opacity: 0;
                    transform: translateX(calc(var(--side-margins) * -1));
                    animation: $animationBaseTime from-opacity-transform $animation-timing-function $delay5 forwards;
                }
            }
        }
    }
}