@import '../../../styles/--scss-vars.scss';

.pr-button-wrapper {
  height: fit-content;
  @include transition(
    background-color #{','} box-shadow #{','} border-color,
    $base-time * 2
  );
  @include text0();
  background-color: var(--submit-background);
  border-radius: var(--radius4);
  box-shadow: var(--submit-box-shadow);
  padding: var(--subdiv) calc(var(--subdiv) * 3);
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  > img {
    display: block;
    user-select: none;
    pointer-events: none;
    margin-right: calc(var(--subdiv) * 2);
  }
  > p {
    @include transition(color, $base-time * 2);
    text-align: center;
    color: var(--submit-color);
    white-space: nowrap;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 4);
  }
  &.tall {
    padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 3);
  }
  &:not(.full-width) {
    width: fit-content;
  }
  &.full-width {
    width: calc(100% - var(--subdiv) * 6);
  }
  &.white {
    --submit-background: #{$color-f5-a};
    --submit-color: #{$color-f0};
    --submit-box-shadow: #{$sh-pop-up-submit-empty};
    --submit-hover-background: #{$color-f4-a};
    --submit-active-background: #{$color-f3-a};
  }
  &.blue {
    --submit-background: #{$color-f0};
    --submit-color: #{$color-f5};
    --submit-box-shadow: #{$sh-pop-up-submit-accept};
    --submit-hover-background: #{$color-f1};
    --submit-active-background: #{$color-f2};
  }
  &.light-blue {
    --submit-background: #{$color-f5-a};
    --submit-color: #{$color-f0};
    --submit-box-shadow: none;
    --submit-hover-background: #{$color-f4-a};
    --submit-active-background: #{$color-f3-a};
  }
  &.light-red {
    --submit-background: #{$color-e5-a};
    --submit-color: #{$color-e0};
    --submit-box-shadow: none;
    --submit-hover-background: #{$color-e4-a};
    --submit-active-background: #{$color-e3-a};
  }

  &.light-yellow {
    --submit-background: #{$color-x8};
    --submit-color: #{$color-w0};
    --submit-box-shadow: none;
    --submit-hover-background: #{$color-x8-a};
    --submit-active-background: #{$color-x8-a};
  }
  &.blue-border {
    --submit-background: #{$color-f3-a};
    --submit-color: #{$color-f0};
    --submit-hover-background: #{$color-f0-1};
    --submit-hover-color: #{$color-f0-1};
    --submit-active-background: #{$color-f1-a};
    --submit-active-color: #{$color-f1-a};

    padding-left: calc(var(--subdiv) * 3);
    padding-right: calc(var(--subdiv) * 3);
    margin: -1px 0;
    border: 1px solid var(--submit-background);
    background-color: transparent;
  }
  &.withArrow {
    > p {
      padding-right: calc(var(--subdiv) * 4);
      position: relative;
      &:after {
        border-bottom: 2px solid $color-f0;
        border-right: 2px solid $color-f0;
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 2px;
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg);
        transition: $time3;
        width: 5px;
      }
    }
  }
  &.withArrow.active {
    background: $color-f0;
    > p {
      color: $color-w0;
      &:after {
        transform: rotate(-135deg);
        border-bottom: 2px solid $color-w0;
        border-right: 2px solid $color-w0;
      }
    }
  }
  &.withIcon {
    align-items: center;
    justify-content: space-between;
    padding: var(--subdiv) calc(var(--subdiv) * 3);
    &:active,
    &:focus {
      background-color: $color-f5-a;
      border-color: $color-w0;
    }
    &.blue-border {
      &:active {
        border-color: transparent;
      }
    }
    &.active {
      border-color: transparent;
      background-color: $color-f5-a;
      &:hover {
        border-color: transparent;
      }
    }
    > p {
      @include text0;
      padding-right: var(--subdiv);
    }
    .tooltip-wrap {
      display: flex;
      &:hover {
        .pr-tooltip-wrapper {
          opacity: 1;
        }
      }
    }
  }
  &.disable {
    --submit-background: #{$color-d4};
    --submit-box-shadow: #{$sh-pop-up-submit-empty};
    pointer-events: none;
    user-select: none;

    > p {
      color: $color-d2;
    }
  }
  @media (pointer: fine) {
    &:not(.blue-border) {
      &:hover {
        background: var(--submit-hover-background);
      }
      &:active {
        background: var(--submit-active-background);
      }
    }
    &.blue-border {
      &:hover {
        border-color: var(--submit-hover-background);

        > p {
          color: var(--submit-hover-color);
        }
      }
      &:active {
        border-color: var(--submit-active-background);

        > p {
          color: var(--submit-active-color);
        }
      }
    }
  }
  @media screen and (max-width: $brake2-1) {
    padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 4);

    &.tall {
      padding: calc(var(--subdiv) * 3) calc(var(--subdiv) * 4);
    }
    &.full-width {
      width: calc(100% - var(--subdiv) * 8);
    }
    &.loading {
      padding: calc(var(--subdiv) * 1.5) calc(var(--subdiv) * 4);
    }
  }
  @media screen and (max-width: $brake-vertical) {
    &.withIcon {
      padding: calc(var(--subdiv) * 2 - 2px) calc(var(--subdiv) * 3);
      > p {
        padding-right: 0;
      }
      > .tooltip-wrap {
        > svg {
          display: none;
        }
      }
    }
  }
}
