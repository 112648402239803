@import '../../../styles/--scss-vars.scss';

.pr-popup-scroll {
  @include pop-up();
  > .pr-popup-inner {
    width: calc(100% - var(--side-margins) * 2);
    max-width: calc(var(--baseline) * 24 + var(--subdiv) * 1);
    margin: 0 var(--side-margins);
    background-color: #fff;
    border-radius: var(--radius7);
    overflow: hidden;
    position: relative;

    > .header {
      height: calc(var(--baseline) * 5);
      padding-top: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include position(absolute, 0, unset, unset, 0);
      background: $color-w7-a;
      backdrop-filter: blur($blur4);
      z-index: 1;
      @-moz-document url-prefix() {
        background: $color-w0;
      }

      > .title {
        @include text3();
        margin-top: var(--baseline);
        display: block;
      }
      > .sub-title {
        @include text0();
      }
    }
    > .main {
      height: fit-content;
      max-height: calc(100vh - var(--side-margins) * 2 - var(--baseline) * 9);
      padding-top: calc(var(--baseline) * 5);
      padding-bottom: calc(var(--baseline) * 4);
      overflow-y: scroll;
      overflow-x: hidden;

      > .text {
        @include body-text();
        margin-bottom: calc(var(--baseline) + var(--subdiv));

        > a {
          color: $color-f0;
        }
      }
    }

    > .footer {
      height: calc(var(--baseline) * 4);
      padding-bottom: 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $color-w3-a;
      backdrop-filter: blur($blur4);
      @include position(absolute, unset, unset, 0, 0);

      > p {
        @include subtitle-1();
        color: $color-d2-a;
      }
    }
  }
  @media only screen and (max-width: $brake4-1) {
    > .pr-popup-inner {
      > .header {
        height: calc(var(--baseline) * 4);
      }
      > .main {
        max-height: calc(100vh - var(--side-margins) * 2 - var(--baseline) * 7);
        padding-top: calc(var(--baseline) * 4);
        padding-bottom: calc(var(--baseline) * 3);

        > .text {
          &:last-of-type {
            margin-bottom: calc(var(--baseline) * 2);
          }
        }
      }
      > .footer {
        height: calc(var(--baseline) * 3);
      }
    }
  }
}

.pr-popup {
  @include pop-up();
  > .pr-popup-inner {
    > .footer {
      display: flex;
      justify-content: space-between;
      margin-top: calc(var(--baseline) + var(--subdiv));
      box-sizing: border-box;
      width: 100%;
      &.center {
        justify-content: center;
        > * {
          margin-right: var(--subdiv);
        }
      }
      &.left {
        justify-content: flex-start;
        > * {
          margin-right: var(--subdiv);
        }
      }
      &.end {
        justify-content: flex-end;
        > * {
          margin-right: var(--subdiv);
        }
      }
      > .pr-button-wrapper {
        &:nth-of-type(2) {
          margin-left: calc(var(--subdiv) * 5);
        }
      }
    }
    > .header {
      @include text3;
      margin-bottom: var(--subdiv);
      > .icon {
        margin: auto;
        margin-bottom: var(--baseline);
        user-select: none;
        pointer-events: none;

        > div {
          height: calc(var(--baseline) * 3 + var(--subdiv) * 2);
          width: calc(var(--baseline) * 3 + var(--subdiv) * 2);
          margin: auto;
          display: none;

          &.warning {
            border-radius: 100%;
            border: 1px solid $color-f3-a;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:before {
              content: '';
              height: var(--baseline);
              width: 3px;
              display: block;
              background-color: $color-f0;
            }
            &:after {
              content: '';
              height: var(--subdiv);
              width: 3px;
              display: block;
              background-color: $color-f0;
              margin-top: calc(var(--subdiv) * 2);
            }
          }
          &.error {
            border-radius: 100%;
            border: 1px solid $color-e0;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:before {
              content: '';
              height: var(--baseline);
              width: 3px;
              display: block;
              background-color: $color-e0;
            }
            &:after {
              content: '';
              height: var(--subdiv);
              width: 3px;
              display: block;
              background-color: $color-e0;
              margin-top: calc(var(--subdiv) * 2);
            }
          }
          &.success {
            border-radius: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            &:before {
              content: '';
              height: 100%;
              width: 100%;
              display: block;
              position: absolute;
              background-image: conic-gradient($color-f6-a, $color-f0);
              background-image: -moz-linear-gradient($color-f6-a, $color-f0);
              border-radius: 100%;
              transform: rotate(0deg);
              animation: rotate $time5 forwards;
            }
            &:after {
              content: '';
              height: calc(100% - 2px);
              width: calc(100% - 2px);
              display: block;
              position: absolute;
              background-color: $color-w0;
              border-radius: 100%;
            }
            > .check-mark {
              height: calc(var(--subdiv) * 3);
              width: calc(var(--baseline) + var(--subdiv));
              display: block;
              border-left: 3px solid $color-f0;
              border-bottom: 3px solid $color-f0;
              box-sizing: border-box;
              transform: rotate(-45deg) scale(0);
              margin-top: calc(var(--subdiv) * -1);
              animation: checkmark-apear $time5 forwards
                cubic-bezier(0.71, 0.54, 0.46, 1.51);
              position: relative;
              z-index: 1;
            }
            @keyframes checkmark-apear {
              0% {
                transform: rotate(-45deg) scale(0);
              }
              100% {
                transform: rotate(-45deg) scale(1);
              }
            }
            @keyframes rotate {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
    .missedMealContent {
      height: 40vh;
      overflow-y: scroll;
      .list-data-meals {
        display: 'flex';
        align-items: 'center';
        padding: '1rem';
        p {
          font: 400 var(--font-size1) 'Lato', sans-serif, open-sans;
          padding-bottom: 4px;
        }
      }
    }
    > .main {
      > * {
        margin: var(--subdiv) 0;
      }
      span.custom-notification {
        font: 400 var(--font-size1) / 1.9 'Lato', sans-serif, open-sans;
      }
      > .title {
        @include text3();
        margin-bottom: calc(var(--subdiv) * 2);
      }
      > .text {
        @include body-text();
        margin-bottom: calc(var(--baseline) + var(--subdiv));

        > a {
          color: $color-f0;
        }

        > .make-link {
          color: $color-f0;
          cursor: pointer;
          user-select: none;
          position: relative;
          pointer-events: all;

          &:after {
            position: absolute;
            content: '';
            height: 1px;
            width: 0;
            left: 0;
            display: block;
            background-color: $color-f0;
            margin-top: -1px;
            @include transition(width);
          }
          &:hover {
            color: $color-f0-2;

            &:after {
              background-color: $color-f0-2;
              width: 100%;
            }
          }
        }
      }
    }
    > .flex {
      display: flex;
      flex-wrap: wrap;
      > * {
        margin: var(--subdiv) 0;
      }
    }
  }

  &[type='warning'] {
    > .pr-popup-inner {
      > .header {
        > .icon {
          > .warning {
            display: flex;
          }
        }
      }
      > .main {
        > .title,
        > .text {
          color: $color-d0;
          user-select: none;
          pointer-events: none;
          text-align: center;
        }
        > .title {
          @include text3();
          margin-bottom: calc(var(--subdiv) * 2);
        }
        > .text {
          @include body-text();
          margin: 0 auto calc(var(--baseline) * 2);
          max-width: var(--column5);
        }
      }
      > .footer {
        justify-content: center;
      }
    }
  }
  &[type='error'] {
    > .pr-popup-inner {
      > .header {
        > .icon {
          > .error {
            display: flex;
          }
        }
      }
      > .main {
        > .title,
        > .text {
          color: $color-d0;
          user-select: none;
          pointer-events: none;
          text-align: center;
        }
        > .title {
          @include text3();
          margin-bottom: calc(var(--subdiv) * 2);
        }
        > .text {
          @include body-text();
          margin: 0 auto calc(var(--baseline) * 2);
          max-width: var(--column5);
        }
      }
      > .footer {
        justify-content: center;
      }
    }
  }
  &[type='success'] {
    > .pr-popup-inner {
      > .header {
        > .icon {
          > .success {
            display: flex;
          }
        }
      }
      > .main {
        > .title,
        > .text {
          color: $color-d0;
          user-select: none;
          pointer-events: none;
          text-align: center;
        }
        > .title {
          @include text3();
          margin-bottom: calc(var(--subdiv) * 2);
        }
        > .text {
          @include body-text();
          margin: 0 auto calc(var(--baseline) * 2);
          max-width: var(--column5);
        }
      }
      > .footer {
        justify-content: center;
      }
    }
  }
  &[type='info'] {
    > .pr-popup-inner {
      > .header {
        > .icon {
          display: none;
        }
      }
      > .title {
        margin-top: var(--subdiv);
      }
      > .text {
        margin: 0 auto calc(var(--baseline) * 2);
        max-width: var(--column5);
      }
      > .buttons {
        > .pr-button-wrapper {
          &:first-child {
            display: none;
          }
        }
      }
      > .footer {
        justify-content: center;
      }
    }
  }

  @media only screen and (max-width: $brake2-1) and (min-width: $brake4-1+1px) {
    > .pr-popup-inner {
      > .main {
        > * {
          margin: calc(var(--subdiv) * 5) 0;
          flex-shrink: 0;
        }
      }
      > .flex {
        > * {
          margin: calc(var(--subdiv) * 5) 0;
          flex-shrink: 0;
        }
      }
    }
  }
  @media only screen and (max-width: $brake4-1) {
    > .pr-popup-inner {
      > .footer {
        > div {
          &:first-child {
            margin-bottom: calc(var(--baseline) / 2);
          }
          &:last-child {
            .white {
              margin-left: 0;
            }
          }
        }
        > * {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: $brake4-1) {
    > .pr-popup-inner {
      > .header {
        > .icon {
          margin-bottom: calc(var(--subdiv) * 4);

          > div {
            height: calc(var(--baseline) * 3 + var(--subdiv));
            width: calc(var(--baseline) * 3 + var(--subdiv));
          }
        }
      }
      > .title {
        margin-bottom: var(--subdiv);
      }
      > .text {
        margin-bottom: calc(var(--baseline) + var(--subdiv) * 2);
      }
    }
    &[type='info'] {
      > .pr-popup-inner {
        > .title {
          margin-top: calc(var(--subdiv) * 4);
          margin-bottom: calc(var(--subdiv) * 2);
        }
        > .text {
          margin-bottom: calc(var(--baseline) + var(--subdiv) * 2);
        }
        > .footer {
          flex-direction: row;
          justify-content: center;
          > * {
            width: auto;
          }
        }
      }
    }
    &[type='warning'] {
      > .pr-popup-inner {
        > .footer {
          flex-direction: row;
          justify-content: center;
          > * {
            width: auto;
          }
        }
      }
    }
    &[type='error'] {
      > .pr-popup-inner {
        > .footer {
          flex-direction: row;
          justify-content: center;
          > * {
            width: auto;
          }
        }
      }
    }
    &[type='success'] {
      > .pr-popup-inner {
        > .footer {
          flex-direction: row;
          justify-content: center;
          > * {
            width: auto;
          }
        }
      }
    }
  }
  &.shrink {
    --header-height: calc(var(--baseline) * 3);
  }
  &:not(.chrome):not(.safari):not(.opera):not(.crios) {
    > .pr-pop-up-header {
      background-image: linear-gradient(180deg, $color-w0 0%, $color-w8-a 100%);
    }
  }
}
