@import '../../../styles/--scss-vars.scss';

.pr-x-mark-wrapper {
  $size: calc(var(--x-mark-size) + var(--subdiv) - 1px);
  @include position(absolute, 0, 0, unset, unset);
  display: flex;
  height: $size;
  width: $size;
  margin: var(--side-margins);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
  * {
    pointer-events: none;
  }
  .x-mark {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    &:before,
    &:after {
      @include transition(
        width #{','} height #{','} background #{','} transform
      );
      content: '';
      display: block;
      position: absolute;
      background: $color-d0;
      transform: rotate(45deg);
    }
    &:before {
      height: 1px;
      width: var(--x-mark-size);
    }
    &:after {
      height: var(--x-mark-size);
      width: 1px;
    }
  }
  > .mask-wrapper {
    @include transition(
      width #{','} height,
      $animationBaseTime * 1.4,
      ease-out
    );
    position: absolute;
    display: flex;
    height: 0px;
    width: 0px;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    > * {
      position: absolute;
    }
    > .mask {
      background: $color-d0;
      border-radius: 50%;
      height: calc(#{$size} * 1.5);
      width: calc(#{$size} * 1.5);
    }
    > .x-mark {
      &:before,
      &:after {
        background: $color-w0;
      }
      &:before {
        width: $size;
      }
      &:after {
        height: $size;
      }
    }
  }
  &.relative {
    position: relative;
    margin: auto 0 auto auto;
  }
  @media (pointer: fine) {
    &:hover {
      > .mask-wrapper {
        @include transition(
          width #{','} height,
          $animationBaseTime * 2,
          ease-out
        );
        height: calc(#{$size} * 1.9);
        width: calc(#{$size} * 1.9);
      }
    }
  }
}
