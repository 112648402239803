@import '../../../styles/--scss-vars.scss';

.pr-tooltip-wrapper {
  --shift-spacing: calc(var(--subdiv) * 3);
  --triangle-width: calc(var(--subdiv) * 1.5);
  --triangle-height: var(--subdiv);
  --tooltip-background: #{$color-w0};
  --tooltip-right-spacing: calc(var(--subdiv) * 8);
  --tooltip-left-spacing: calc(var(--subdiv) * 6);

  pointer-events: none;
  opacity: 0;
  @include transition(opacity, $base-time * 2);
  position: absolute;

  > ul {
    position: absolute;
    background: var(--tooltip-background);
    box-shadow: $sh-tooltip;
    padding: calc(var(--subdiv) * 3) var(--tooltip-right-spacing)
      calc(var(--subdiv) * 3) var(--tooltip-left-spacing);
    border-radius: var(--radius6);
    > li {
      position: relative;
      display: flex;
      align-items: center;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
      &.pointer {
        cursor: pointer;
        > p {
          cursor: pointer;
        }
      }
      &:before {
        background-color: var(--tooltip-background);
        @include transition(background-color);
        content: '';
        display: block;
        @include position(
          absolute,
          0,
          calc(var(--tooltip-right-spacing) * -1),
          0,
          calc(var(--tooltip-left-spacing) * -1)
        );
        height: 100%;
        width: 100%;
      }
      > * {
        position: relative;
        user-select: none;
      }
      > .ico-wrapper {
        display: flex;
        margin-right: calc(var(--subdiv) * 3);
      }
      > ul {
        li {
          @include text-1();
          line-height: 1.5;
          padding: 3px calc(var(--subdiv) * 2 + 2px);
          position: relative;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: calc(var(--subdiv) + 1px);
            height: calc(var(--subdiv) + 1px);
            border-radius: 100%;
            background: $color-d0;
            top: calc(var(--subdiv) * 3 - 3px);
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      > p {
        @include text0();
        padding: calc(var(--subdiv) * 1) 0;

        > a {
          color: $color-f0;
          @include transition(color, $time3);
          cursor: pointer;

          @media (pointer: fine) {
            &:hover {
              color: $color-f0-2;
            }
          }
        }
      }
      @media (pointer: fine) {
        &:hover {
          &:not(.description):not(.title) {
            cursor: pointer;
            &:before {
              background-color: $color-d6;
            }
          }
        }
      }
      &.description,
      &.title {
        width: fit-content;
        display: block;
        pointer-events: none;
      }
      &.title {
        > p {
          @include subtitle-1();
          color: $color-d2;
        }
      }
      &.row {
        display: flex;

        > .title {
          margin-right: calc(var(--subdiv) * 3);
        }
      }
    }
  }
  &.long {
    > ul {
      width: calc(var(--baseline) * 10);
    }
  }
  > .triangle {
    display: none;
    position: absolute;
    border-left: var(--triangle-width) solid rgba(255, 255, 255, 0);
    border-right: var(--triangle-width) solid rgba(255, 255, 255, 0);
    border-top: var(--triangle-height) solid var(--tooltip-background);
  }
  &.with-extra-cta {
    > ul {
      > :last-child {
        > p {
          color: $color-e0;
        }
        @media (pointer: fine) {
          &:hover {
            &:before {
              background-color: rgba($color-e0, 0.03);
            }
          }
        }
      }
    }
  }

  &.error {
    > ul {
      > li {
        &.title {
          > p {
            color: $color-e0;
          }
        }
      }
    }
  }

  &.blue {
    --tooltip-background: #{$color-f0-1};

    > ul {
      p {
        color: $color-w0;

        &.title {
          color: $color-w6-a;
        }
      }
    }
  }

  &.black {
    --tooltip-background: #{$color-d0};
    > ul {
      p {
        color: $color-w0;

        &.title {
          color: $color-w0;
        }
      }
    }
  }

  &.full-width {
    width: 100%;

    > ul {
      width: calc(
        100% - var(--tooltip-right-spacing) - var(--tooltip-left-spacing)
      );
    }
  }

  &.top-right {
    top: -1px;
    right: 0;
    height: 1px;
    width: 100%;
    > ul {
      bottom: calc(var(--subdiv) * 2);
    }
    &:not(.shift) {
      > ul {
        right: 0;
      }
    }
    &.shift {
      > ul {
        right: calc(var(--shift-spacing) * -1);
      }
    }
  }

  &.top-left {
    top: -1px;
    left: calc(var(--subdiv) * 4);
    height: 1px;
    width: 100%;

    > ul {
      bottom: calc(var(--subdiv) * 2);
    }
  }

  &.bottom-right {
    right: 0;
    height: 1px;
    bottom: -1px;
    > ul {
      top: calc(var(--subdiv) * 2);
    }
    &:not(.shift) {
      > ul {
        right: 0;
      }
    }
    &.shift {
      > ul {
        right: calc(var(--shift-spacing) * -1);
      }
    }
  }

  &.bottom-left {
    bottom: -1px;
    left: calc(var(--subdiv) * 4);
    height: 1px;
    width: 100%;

    > ul {
      // bottom: calc(var(--subdiv) * 2);
      bottom: auto;
    }
  }

  &.right {
    left: calc(100% + var(--subdiv));
    height: 1px;
    width: 100%;
    top: 15px;
    > ul {
      top: calc(var(--subdiv) * 2);
      transform: translateY(-50%);
    }
  }
  &.width-by-content {
    > ul {
      width: fit-content;
    }
  }

  &.with-triangle {
    &.top-right {
      > ul {
        bottom: calc(var(--triangle-height) * 2);
      }
      > .triangle {
        display: block;
        left: calc(50% - var(--triangle-width));
        right: 50%;
        transform: translateX(50%);
        bottom: var(--triangle-height);
      }
    }

    &.top-left {
      > ul {
        bottom: calc(var(--triangle-height) * 2 + 1px);
      }
      > .triangle {
        display: block;
        left: var(--baseline);
        bottom: var(--triangle-height);
      }
    }

    &.bottom-left {
      > ul {
        top: calc(var(--triangle-height) * 2 - 1px);
      }
      > .triangle {
        display: block;
        left: var(--baseline);
        top: var(--triangle-height);
        transform: rotate(180deg);
      }
    }
    &.bottom-right {
      > ul {
        top: calc(var(--triangle-width) - 1px);
      }
      > .triangle {
        display: block;
        right: calc(var(--triangle-width) / 2 + var(--subdiv) * 2);
        bottom: -4px;
        transform: rotate(180deg);
      }
    }
    &.right {
      > ul {
        left: calc(var(--triangle-width) - 1px);
      }
      > .triangle {
        display: block;
        left: calc(var(--triangle-width) * -0.5);
        top: calc(50% - var(--triangle-width) / 2);
        transform: rotate(90deg);
      }
    }
    &.bottom-center {
      > ul {
        top: calc(var(--triangle-height) * 2 + 1px);
      }
      > .triangle {
        display: block;
        top: calc(var(--triangle-height) + 1px);
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
    &.top-center {
      > ul {
        bottom: calc(var(--triangle-height) * 2 + 1px);
      }
      > .triangle {
        display: block;
        bottom: calc(var(--triangle-height) + 1px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.large {
    --triangle-height: calc(var(--subdiv) * 2);
    --triangle-width: calc(var(--subdiv) * 2);

    width: calc(var(--baseline) * 9 + var(--subdiv) * 2);
    z-index: 99;

    > ul {
      height: fit-content;
    }
  }

  &.in-view {
    opacity: 1;
    pointer-events: all;
  }
}

.mob-space {
  margin-top: 10px;
}
.red-tooltip {
  position: absolute;

  .pr-tooltip-wrapper {
    top: calc(var(--subdiv) * 8);
  }
  ul {
    background: #e32956;
    min-width: calc(var(--subdiv) * 30);
    padding: calc(var(--subdiv) * 1);
    padding-left: calc(var(--subdiv) * 3);
    padding-right: calc(var(--subdiv) * 3);
    border-radius: 5px;
    left: -60px;
    z-index: 1;
    li {
      position: relative;
      display: flex;
      align-items: center;
      &::before {
        background-color: #e32956;
        right: auto;
        width: auto;
      }
    }
    p {
      color: #fff;
    }
  }
  .triangle {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e32956;
    border-top: none;
    display: block;
    top: -10px;
    position: absolute;
  }
}

.info-desc {
  > ul {
    padding: calc(var(--subdiv) * 1);
    padding-left: calc(var(--subdiv) * 3);
    padding-right: calc(var(--subdiv) * 3);
    border-radius: 5px;
    li {
      p {
        font: 400 var(--font-size1) / 1.42 'Lato', sans-serif, open-sans;
        padding: 0px;
      }
    }
  }
}
