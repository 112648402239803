@import '--scss-vars.scss', '--css-vars.scss', '--css-global-styles.scss',
  '--reset.scss';

@font-face {
  font-family: GucciSansProBold;
  src: url(../assets/fonts/GucciSansPro-Bold.otf);
}

#__bs_script__ {
  display: none;
}
*::-webkit-scrollbar {
  width: 0px;
  height: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
html {
  &.no-scroll {
    height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
  }
  &.no-scroll-second {
    height: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
  }
}
body {
  width: 100%;
  &.no-scroll {
    height: 100%;
  }
  > #root {
    height: 100%;
    width: 100%;

    // for presentation
    > .page-wrapper {
      @include transition(margin-top, $base-time * 3);
      margin-top: 0vh;

      &.pop-up-in-view {
        margin-top: 15vh;
      }
    }
  }
  .main-grid {
    height: 100%;
    width: 100%;
    @include position(fixed, 0, unset, unset, 0);
    pointer-events: none;
    display: none;
    background-image: linear-gradient(
        to bottom,
        rgba(red, 0.3) 0.6px,
        rgba(255, 255, 255, 0) 0.6px
      ),
      linear-gradient(
        to right,
        rgba(red, 0.3) 0.6px,
        rgba(255, 255, 255, 0) 0.6px
      );
    background-repeat: repeat;
    background-size: var(--subdiv) var(--subdiv);
    z-index: 9999;
  }
}
