@import '../../styles/--scss-vars';
/* made the below change in order to wrap the long text inside popup screen
** by Neeraj kuar 
** on 9th june 2021 
** AJNA-5959 */
.error-popup {
  z-index: 1001;
  .main {
    .title {
      user-select: inherit !important;
      pointer-events: visible !important;
      a {
        color: rgb(14, 111, 190);
        font: 400 var(--font-size4) / 1.66 'Lato', sans-serif, open-sans;
        word-break: break-all;
        position: relative;
        display: inline-block;
        &::after {
          position: absolute;
          content: '';
          height: 1px;
          width: 0;
          display: block;
          background-color: #4a85fb;
          margin-top: -1px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
          transition-delay: 0s;
        }
      }
      .href-login{
        font: 400 var(--font-size6) 'Lato', sans-serif, open-sans;
      }
      .sub-title {
        font: 400 var(--font-size3) / 1.45 'Lato', sans-serif, open-sans;
        text-align: center;
        margin-top: calc(var(--subdiv) * 3);
      }
    }
  }
}
