@import  '../--scss-vars.scss';

.x-mark-wrapper{
    >.x-mark{
        @include transition(transform #{','} opacity, $base-time * 3);
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        &:before, &:after{
            @include transition(width #{','} height #{','} background #{','} transform);
            content: '';
            display: block;
            position: absolute;
            background: $color-d0;
            transform: rotate(45deg);
        }
        &:before{
            height: 1px;
            max-width: calc(var(--x-size) - var(--subdiv));
            width: 100%;
        }
        &:after{
            max-height: calc(var(--x-size) - var(--subdiv));
            height: 100%;
            width: 1px;
        }
        &.white{
            &:before, &:after{
                background: $color-w0;
            }
        }
    }
}