.layout-2{
    height: fit-content;
    width: 100%;
    margin-bottom: var(--vertical-spacing);
    @import './slider';
    >.title-wrapper{
        margin-bottom: calc(var(--baseline) + var(--subdiv));
        >h3{
            @include text5();
            width: calc(100% - var(--side-spacing) * 2);
            max-width: var(--column10);
            margin: 0 auto calc(var(--baseline) * 2) auto;
            padding: 0 var(--side-spacing);
            text-align: center;
        }
        >.subtitle{
            @include subtitle-1();
            position: relative;
            display: flex;
            text-align: center;
            justify-content: center;
            &:before{
                content: '';
                display: block;
                position: absolute;
                top: calc(var(--subdiv) * -4);
                height: 1px;
                width: calc(var(--subdiv) * 4);
                background: $color-d0;
            }
        }
    }
    >nav{
        display: flex;
        width: fit-content;
        margin: 0 auto;
        align-items: center;
        >span{
            display: flex;
            align-items: center;
            user-select: none;
            &.arrow{
                $size: calc(var(--subdiv) * 3);
                @include transition(border);
                position: relative;
                height: $size;
                width: $size;
                border-right: 1px solid $color-d0;
                border-bottom: 1px solid $color-d0;
                cursor: pointer;
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    height: 50%;
                    width: 150%;
                    padding: var(--subdiv);
                    left: calc(var(--subdiv) * -1);
                    top: calc(var(--subdiv) * .5);
                    transform: rotate(-45deg);
                }
            }
            &.to-left{
                transform: rotate(135deg);
            }
            &.to-right{
                transform: rotate(-45deg);
            }
            &.counter{
                @include text-1();
                margin: 0 var(--baseline);
                >span{
                    margin: 0 var(--subdiv);
                }
            }
        }
        &[endPoint~='first']{
            >span{
                &.to-left{
                    pointer-events: none;
                    border-color: $color-d5-a;
                }
            }
        }
        &[endPoint~='last'], &[endPoint~='last-answered']{
            >span{
                &.to-right{
                    pointer-events: none;
                    border-color: $color-d5-a;
                }
            }
        }
    }
    &.transition-hold{
        *{
            transition: none;
        }
    }
    @media only screen and (max-width: $brake4), only screen and (max-height: $brake-vertical) {
        >.title-wrapper{
            >h3{
                margin: 0 auto calc(var(--subdiv) * 5) auto;
            }
            >.subtitle{
                display: none;
            }
        }
        >nav{
            >span{
                &.to-left{
                    margin-right: var(--subdiv);
                }
                &.counter{
                    display: none;
                }
            }
        }
    }
}
