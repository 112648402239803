@mixin font($f-family, $f-weight, $f-size, $l-spacing, $l-height, $case) {
  font: $f-weight #{$f-size}/#{$l-height} $f-family, sans-serif, open-sans;
  letter-spacing: $l-spacing;
  text-transform: $case;
}

// @mixin font-smoothing($value: on) {
//   @if $value == on {
//     font-smoothing: antialiased;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
//   @else {
//     -webkit-font-smoothing: subpixel-antialiased;
//     -moz-osx-font-smoothing: auto;
//   }
// }
