>.content-wrapper{
    $size: $column4 + $subdiv * 10;
    $spacing-vertical: var(--baseline);
    // $spacing-horizontal: calc((100vw - #{$size}) / 2);
    position: relative;
    width: 100%;
    overflow: hidden;
    >.wrapper-with-mask{
        width: 100%;
        display: flex;
        justify-content: center;
        >span{
            // @include transition(background, 0s);
            // transition-delay: 1s;
            display: none;
            z-index: 1;
            content: '';
            width: 100%;
            &:first-child{
                background: linear-gradient(to left, rgba($color-w0, 0), rgba($color-w0, 0), rgba($color-w0, 1));
            }
            &:last-child{
                background: linear-gradient(to right, rgba($color-w0, 0), rgba($color-w0, 0), rgba($color-w0, 1));
            }
        }
        >.mask{
            opacity: 0;
            min-width: $size;
            pointer-events: none;
            margin-bottom: calc(#{$spacing-vertical} + var(--subdiv) * 3);
            >* >:first-child{
                margin-bottom: $spacing-vertical;
            }
        }
        >.list-wrapper{
            position: absolute;
            width: $size;
            >ul{
                @include transition(margin, $animationBaseTime * 3);
                position: absolute;
                display: flex;
                width: fit-content;
                >li{
                    opacity: 0;
                    @include transition(opacity, $animationBaseTime);
                    width: $size;
                    >*{
                        height: calc(100% / 2 - #{$spacing-vertical} / 2);
                        &:first-child{
                            margin-bottom: $spacing-vertical;
                        }
                    }
                    &:not(:last-child){
                        margin-right: calc((100vw - #{$size} - var(--left-side-bar-width) + var(--left-side-bar-spacing)) / 2);
                    }
                }
            }
            @for $index from 1 to 20 {
                &[state='#{$index}']{
                    >ul{
                        margin-left: calc((#{$size} + calc((100vw - #{$size} - var(--left-side-bar-width) + var(--left-side-bar-spacing)) / 2)) * -#{$index - 1});
                        >li:nth-child(#{$index}){
                            opacity: 1;
                        }
                    }
                }
            }
        }
        // &.show-shapes{
        //     >span{
        //         display: block;
        //     }
        // }
    }
    @media only screen and (max-width: $brake4), only screen and (max-height: $brake-vertical) {
        $size: calc(100vw - var(--side-margins) * 2);
        $spacing-vertical: calc(var(--subdiv) * 2);
        // $spacing-horizontal: calc(var(--side-margins) * 2);
        overflow: unset;
        >.wrapper-with-mask{
            >span{
                &:first-child, &:last-child{
                    display: none;
                }
            }
            >.mask{
                margin-bottom: calc(#{$spacing-vertical} + var(--subdiv) * 3);
                >* >:first-child{
                    margin-bottom: $spacing-vertical;
                }
            }
            >.list-wrapper{
                width: $size;
                >ul{
                    >li{
                        width: $size;
                        >*{
                            height: calc(100% / 2 - #{$spacing-vertical} / 2);
                            &:first-child{
                                margin-bottom: $spacing-vertical;
                            }
                        }
                        &:not(:last-child){
                            margin-right: calc(var(--side-margins) * 2);
                        }
                    }
                }
                @for $index from 1 to 15 {
                    &[state='#{$index}']{
                        >ul{
                            margin-left: calc((#{$size} + calc(var(--side-margins) * 2)) * -#{$index - 1});
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $brake5-1) {
        >.wrapper-with-mask{
            >.mask{
                margin-bottom: calc(#{$spacing-vertical} + var(--baseline));
            }
        }
    }
    @media only screen and (max-width: $brake5-2) {
        >.wrapper-with-mask{
            >.mask{
                margin-bottom: calc(#{$spacing-vertical} + var(--baseline) * 3);
            }
        }
    }
}