@import '../../styles/--scss-vars.scss';

.real-time-notification-message {
  height: fit-content;
  width: calc(var(--baseline) * 13);
  max-height: calc(100% - var(--header-height) + var(--baseline));
  @include position(fixed, 0, 0, unset, unset);
  padding-right: calc(var(--subdiv) * 4);
  margin-top: calc(var(--header-height) - var(--baseline));
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1063;

  > li {
    width: 100%;
    background-color: $color-f0;
    $property: background-color, margin-top, opacity, transform;
    $time: $time3, $time4, $time4, $time3;
    $delay: 0s, $time1, 0s, 0s;
    @include transition($property, $time, $delay);
    border-radius: var(--radius7);
    margin-bottom: calc(var(--subdiv) * 3);
    position: relative;
    height: 0;
    animation: scale-up $time5 forwards;
    overflow: hidden;
    cursor: pointer;

    > .card-content {
      height: fit-content;
      width: calc(100% - var(--baseline) * 2);
      padding: calc(var(--subdiv) * 5) var(--baseline);
      opacity: 0;
      animation: apear $time5 $time3 forwards;

      > .title-wrapper {
        display: flex;
        position: relative;
        margin-bottom: calc(var(--subdiv) * 3);

        > p {
          @include subtitle-1();
          user-select: none;
          pointer-events: none;
          color: $color-w0;

          &.date {
            margin-left: calc(var(--subdiv) * 2);
            color: $color-w8-a;
          }
        }
      }
      > .description {
        @include text-1();
        user-select: none;
        pointer-events: none;
        color: $color-w0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > .x-mark {
        height: calc(var(--subdiv) * 3);
        width: calc(var(--subdiv) * 3);
        @include position(
          absolute,
          calc(var(--subdiv) * 4),
          calc(var(--subdiv) * 4),
          unset,
          unset
        );
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          height: 200%;
          width: 200%;
          border-radius: 100%;
          @include position(absolute, -50%, unset, unset, -50%);
        }
        > svg {
          pointer-events: none;

          > path {
            stroke: $color-w0;
          }
        }
      }
    }
    &.removed {
      margin-top: calc(var(--card-height) * -1 - var(--subdiv) * 3);
      transform: translateX(calc(100% + var(--subdiv) * 3));
      opacity: 0;
    }
    @media (pointer: fine) {
      &:hover {
        background-color: $color-f1;
      }
    }
    @keyframes scale-up {
      0% {
        height: 0;
      }
      100% {
        height: var(--card-height);
      }
    }
    @keyframes apear {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: $brake4-1) {
    margin: 0 calc(var(--subdiv) * 3);
    margin-top: calc(var(--subdiv) * 3);
    width: calc(100% - var(--subdiv) * 6);
    padding: 0;
    max-height: unset;
    display: flex;
    justify-content: center;
    overflow: unset;

    > li {
      @include position(absolute, 0, unset, unset, unset);
      height: fit-content;
      transform: translateY(calc(var(--card-height) * -1 - var(--subdiv) * 3));
      animation: slide-down $time5 forwards;
      margin-bottom: 0;
      $property: background-color, opacity, top, width;
      $time: $time3, $time4, $time6, $time4;
      $delay: 0s, $time1, 0s, 0s;
      @include transition($property, $time, $delay);

      &:first-child {
        z-index: 2;
      }
      &:nth-child(2) {
        z-index: 1;
        width: calc(100% - var(--subdiv) * 10);
        top: var(--subdiv);
        opacity: 0.5;
      }
      &:nth-child(3) {
        width: calc(100% - var(--subdiv) * 16);
        top: calc(var(--subdiv) * 2);
        opacity: 0.15;
      }
      &:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
        width: calc(100% - var(--subdiv) * 16);
        top: calc(var(--subdiv) * 2);
        opacity: 0;
      }
      &.removed {
        margin-top: unset;
        transform: unset;
        opacity: 0;
        top: calc(var(--card-height) * -1 - var(--subdiv) * 3);

        ~ li {
          &:nth-child(2) {
            z-index: 2;
            width: 100%;
            top: 0;
            opacity: 1;
          }
          &:nth-child(3) {
            z-index: 1;
            width: calc(100% - var(--subdiv) * 10);
            top: var(--subdiv);
            opacity: 0.5;
          }
          &:nth-child(4) {
            width: calc(100% - var(--subdiv) * 16) !important;
            top: calc(var(--subdiv) * 2) !important;
            opacity: 0.15 !important;
          }
          &:not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
            width: calc(100% - var(--subdiv) * 16);
            top: calc(var(--subdiv) * 2);
            opacity: 0;
          }
        }
      }
      @keyframes slide-down {
        0% {
          transform: translateY(
            calc(var(--card-height) * -1 - var(--subdiv) * 3)
          );
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }
}
