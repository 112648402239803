// arrow button
$sh0: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline) rgba($color-d0, 0);
$sh1: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline) rgba($color-d0, 0.1);
$sh1-1: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline)
  rgba($color-d0, 0.02);
$sh2: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline) rgba($color-f0, 0.1);

// slides
$sh3: 0px 0px calc(var(--baseline) * 6) #{rgba($color-f0-2, $opacity1)};
$sh4: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline)
  rgba($color-f0, 0.05);
// pop up
// $sh-tooltip: 0px calc(var(--subdiv) * 3) calc(var(--subdiv) * 3) 0px rgba($color-f0-2,.05);
$sh-tooltip: 0px 0px calc(var(--subdiv) * 5) 0px rgba($color-f0-2, 0.1);

// btns from floating message
$sh-pop-up-submit-empty: 0px 0px 0px 0px rgba($color-w0, 0);
$sh-pop-up-submit-accept: 0px var(--subdiv) calc(var(--subdiv) * 2) 0px
  rgba($color-f0, 0.2);
$sh-pop-up-submit-cancel: 0px var(--subdiv) calc(var(--subdiv) * 2) 0px
  rgba($color-e0, 0.2);

// pr-avatar-circle
$sh-avatar-ico-extra-red: 0px calc(var(--subdiv) * 2) calc(var(--subdiv) * 4)
  0px rgba($color-e0, 0.25);
$sh-avatar-ico-fusion-blue: 0px calc(var(--subdiv) * 2) calc(var(--subdiv) * 4)
  0px rgba($color-f0, 0.25);

// range slider
$sh-range-slider-dot: 0px var(--subdiv) calc(var(--subdiv) * 2) 0px
  rgba($color-f0, 0.25);
$sh-range-slider-dot-disabled: 0px var(--subdiv) calc(var(--subdiv) * 2) 0px
  rgba($color-d2, 0.25);
$sh-range-slider-tip: 0px calc(var(--subdiv) * 2) calc(var(--subdiv) * 4) 0px
  rgba($color-d0, 0.05);

// error-message
$sh-error-message: 0px calc(var(--subdiv) * 2) calc(var(--subdiv) * 4) 0px
  rgba($color-e0, 0.17);

// normal-message
$sh-message: 0px calc(var(--subdiv) * 2) calc(var(--subdiv) * 5) 0px
  rgba(#000, 0.1);

// cards
$sh-card-dark-unset: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline) 0px
  rgba($color-d0, 0);
$sh-card-blue-unset: 0px calc(var(--subdiv) * 3) var(--baseline) 0px
  rgba($color-f0, 0);
$sh-card-dark: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline) 0px
  rgba($color-d0, 0.1);
$sh-card-dark-2: var(--subdiv) calc(var(--subdiv) * 3) var(--baseline) 0px
  rgba($color-d0, 0.2);
$sh-card-dark-3: 0px 0px var(--baseline) 0px rgba($color-d0, 0.05);
$sh-card-blue: 0px calc(var(--subdiv) * 3) var(--baseline) 0px
  rgba($color-f0, 0.3);

// mazur shadows
$dark-sh: 0px var(--subdiv) calc(var(--subdiv) * 5) var(--subdiv) $color-d6-a;
$darker-sh: 0px var(--subdiv) calc(var(--subdiv) * 5) var(--subdiv) $color-d5-a;
$darkest-sh: 0px var(--subdiv) calc(var(--subdiv) * 4) 0 $color-d4-a;

// map shadows
$location-sh: 0px var(--subdiv) calc(var(--subdiv) * 14) $color-d3-a;
