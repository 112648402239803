@import '../../../styles/--scss-vars.scss';

.loading {
  z-index: 101;
  svg {
    z-index: 102;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }
  &.full {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
  }
  &.inner {
    position: absolute;
  }
}
