@import '../../styles/--scss-vars.scss', '../../styles/--css-vars.scss';
.page-footer {
  background: $color-bg;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  padding: var(--baseline) calc(var(--subdiv) * 3);
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  text-align: center;

  p {
    font: 600 var(--font-size1) / 1.14 'Lato', sans-serif, open-sans;
    color: $color-d0;
    opacity: 0.7;
  }
}
