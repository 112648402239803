@import '--scss-vars.scss';

body {
  --font-size0: 12px;
  --font-size1: 14px;
  --font-size2: 16px;
  --font-size3: 18px;
  --font-size4: 21px;
  --font-size5: 25px;
  --font-size6: 30px;
  --font-size7: 35px;
  --font-size8: 41px;
  --font-size9: 48px;
  --font-size10: 57px;
  --font-size11: 57px;
  --font-size12: 79px;
  --font-size13: 93px;
  --font-size14: 110px;
  --font-size16: 139px;

  @-moz-document url-prefix() {
    --font-size0: 12px;
    --font-size1: 14px;
    --font-size2: 16px;
    --font-size3: 18px;
    --font-size4: 21px;
    --font-size5: 25px;
    --font-size6: 30px;
    --font-size7: 35px;
    --font-size8: 41px;
    --font-size9: 48px;
    --font-size10: 57px;
    --font-size11: 57px;
    --font-size12: 79px;
    --font-size13: 93px;
    --font-size14: 110px;
  }

  --font-base: 18px;
  --baseline: 30px;
  --subdiv: 5px;
  --column-width: 70px;
  --gutter: 30px;
  --side-margins: 75px;
  //columns
  --column-width: 70px;
  --column1: 70px;
  --column2: 170px;
  --column3: 270px;
  --column4: 370px;
  --column5: 470px;
  --column6: 570px;
  --column7: 670px;
  --column8: 770px;
  --column9: 870px;
  --column10: 970px;
  --column11: 1070px;
  --column12: 1170px;
  //spacers
  --spacer1: 30px;
  --spacer2: 60px;
  --spacer3: 90px;
  --spacer4: 120px;
  --spacer5: 150px;
  --spacer6: 180px;
  // radiuses
  --radius1: 2.5px;
  --radius2: 5px;
  --radius3: 7.5px;
  --radius4: 10px;
  --radius5: 12.5px;
  --radius6: 15px;
  --radius7: 17.5px;
  --radius8: 20px;
  --radius9: 22.5px;
  //custom
  --side-spacing: calc(var(--side-margins) * 2);
  --vertical-spacing: var(--side-margins);

  --scroll-bar-height: 100%;
  --scroll-bar-cursor-height: 20vh; // linked in js to window.innerHeight * .2

  --previous1-bonus: var(--baseline);
  --previous2-bonus: calc(var(--baseline) + var(--subdiv) * 4);
  --previous3-bonus: calc(var(--baseline) + var(--subdiv) * 7);
  --left-side-bar-width: calc(var(--baseline) * 10 - var(--subdiv));
  --left-side-bar-spacing: 0px;

  --header-height: calc(var(--baseline) * 5);
  --logo-width: calc(var(--baseline) * 7);
  --floating-message-height: calc(var(--subdiv) * 20);
  --floating-message-height-2: var(--floating-message-height);
  --space-fixer: 0px;

  --input-border-default-color: #{$color-d3-a};
  --input-border-filled-color: #{$color-d4-a};
  --input-border-hover-color: #{$color-d1-a};
  --input-border-focus-color: #{$color-f0};
  --input-placeholder-color: #{$color-d3-a};
  --input-placeholder-span-color: #{$color-d2-a};
  --input-border-error-color: #{$color-e0};
  --input-text-error-color: #{$color-e0};

  --x-mark-size: 25px;
  --sheet-header-divider-color: #{$color-d5};

  --popup-footer-spacer: calc(var(--baseline) + var(--subdiv) * 6);

  @media screen and (max-width: $brake2-1) {
    --font-size0: 11px;
    --font-size1: 13px;
    --font-size2: 15px;
    --font-size3: 16px;
    --font-size4: 19px;
    --font-size5: 22px;
    --font-size6: 26px;
    --font-size7: 31px;
    --font-size8: 37px;
    --font-size9: 44px;
    --font-size10: 52px;
    --font-size11: 61px;
    --font-size12: 72px;
    --font-size13: 85px;
    --font-size14: 100px;
    --font-size15: 118px;
    --font-size16: 139px;
    --font-size17: 164px;
    --font-size18: 194px;

    @-moz-document url-prefix() {
      --font-size0: 11px;
      --font-size1: 13px;
      --font-size2: 15px;
      --font-size3: 16px;
      --font-size4: 19px;
      --font-size5: 22px;
      --font-size6: 26px;
      --font-size7: 31px;
      --font-size8: 37px;
      --font-size9: 44px;
      --font-size10: 52px;
      --font-size11: 61px;
      --font-size12: 72px;
      --font-size13: 85px;
      --font-size14: 100px;
      --font-size15: 118px;
      --font-size16: 139px;
      --font-size17: 164px;
      --font-size18: 194px;
    }

    --font-base: 16px;
    --baseline: 24px;
    --subdiv: 4px;
    --gutter: 24px;
    --side-margins: 48px;
    --column-width: 68px;
    --columns: 12;

    --column1: 68px;
    --column2: 160px;
    --column3: 252px;
    --column4: 344px;
    --column5: 436px;
    --column6: 528px;
    --column7: 620px;
    --column8: 712px;
    --column9: 804px;
    --column10: 896px;
    --column11: 988px;
    --column12: 1080px;

    --spacer1: 24px;
    --spacer2: 48px;
    --spacer3: 72px;
    --spacer4: 96px;
    --spacer5: 120px;
    --spacer6: 144px;

    --radius1: 2px;
    --radius2: 4px;
    --radius3: 6px;
    --radius4: 8px;
    --radius5: 10px;
    --radius6: 12px;
    --radius7: 14px;
    --radius8: 16px;
    --radius8: 18px;

    --logo-width: calc(var(--baseline) * 8);
  }
  @media only screen and (max-width: $brake3 - 14),
    only screen and (max-height: $brake-vertical) {
    --side-margins: 24px;
    --side-spacing: var(--side-margins);
    --vertical-spacing: calc(var(--baseline) + var(--subdiv));
    --logo-width: calc(var(--baseline) * 3);
    --header-height: calc(var(--baseline) * 3);
  }
  @media only screen and (max-width: $brake4-1),
    only screen and (max-height: $brake-vertical) {
    --header-height: calc(var(--subdiv) * 14);
    --logo-width: calc(var(--subdiv) * 27);
    --x-mark-size: 20px;
    --floating-message-height-2: calc(var(--baseline) * 4);
  }
}
