@import "./animation.scss";

$baseTime:$base-time;
$animationBaseTime:$baseTime*1.5;
$animationBaseTimePart: $animationBaseTime / 2;
$delay-multiplayer: $animationBaseTimePart * 0;

$delay0: $animationBaseTime * 0;

$timePice1:$animationBaseTime;
$delay1: $animationBaseTime * .3;
$delay-loop1: $delay1 - $animationBaseTimePart;

$timePice2:$baseTime*1 - $delay-multiplayer;
$delay2:$timePice1 + $delay1;
$delay-loop2: $delay2 - $animationBaseTimePart;

$timePice3:$baseTime*1 - $delay-multiplayer;
$delay3:$timePice2 + $delay2;
$delay-loop3: $delay3 - $animationBaseTimePart;

$timePice4:$baseTime*1 - $delay-multiplayer;
$delay4:$timePice3 + $delay3;
$delay-loop4: $delay4 - $animationBaseTimePart;

$timePice5:$baseTime*1 - $delay-multiplayer;
$delay5:$timePice4 + $delay4;
$delay-loop5: $delay5 - $animationBaseTimePart;

$timePice6:$baseTime*1 - $delay-multiplayer;
$delay6:$timePice5 + $delay5;
$delay-loop6: $delay6 - $animationBaseTimePart;

$timePice7:$baseTime*1 - $delay-multiplayer;
$delay7:$timePice6 + $delay6;
$delay-loop7: $delay7 - $animationBaseTimePart;

$timePice8:$baseTime*1 - $delay-multiplayer;
$delay8:$timePice7 + $delay7;

$timePice9:$baseTime*1 - $delay-multiplayer;
$delay9:$timePice8 + $delay8;

$timePice10:$baseTime*1 - $delay-multiplayer;
$delay10:$timePice9 + $delay9;

$timePice11:$baseTime*1 - $delay-multiplayer;
$delay11:$timePice10 + $delay10;

$timePice12:$baseTime*1 - $delay-multiplayer;
$delay12:$timePice11 + $delay11;

$timePice13:$baseTime*1 - $delay-multiplayer;
$delay13:$timePice12 + $delay12;

$timePice14:$baseTime*1 - $delay-multiplayer;
$delay14:$timePice13 + $delay13;

$timePice15:$baseTime*1 - $delay-multiplayer;
$delay15:$timePice14 + $delay14;

$timePice16:$baseTime*1 - $delay-multiplayer;
$delay16:$timePice15 + $delay15;

$timePice17:$baseTime*1 - $delay-multiplayer;
$delay17:$timePice16 + $delay16;



$delay2-1:$timePice1 + $delay1 + $animationBaseTimePart;
$delay2-2:$timePice1 + $delay1 + $animationBaseTimePart * 2;

$delay3-1:$timePice2 + $delay2 + $animationBaseTimePart;
$delay3-2:$timePice2 + $delay2 + $animationBaseTimePart * 2;

$delay4-1:$timePice3 + $delay3 + $animationBaseTimePart * .5;
$delay4-2:$timePice3 + $delay3 + $animationBaseTimePart * 2;

$delay5-1:$timePice4 + $delay4 + $animationBaseTimePart;
$delay5-2:$timePice4 + $delay4 + $animationBaseTimePart * 2;

$delay6-1:$timePice5 + $delay5 + $animationBaseTimePart;
$delay6-2:$timePice5 + $delay5 + $animationBaseTimePart * 2;

$delay7-1:$timePice6 + $delay6 + $animationBaseTimePart;
$delay7-2:$timePice6 + $delay6 + $animationBaseTimePart * 2;

$delay8-1:$timePice7 + $delay7 + $animationBaseTimePart;
$delay8-2:$timePice7 + $delay7 + $animationBaseTimePart * 2;

$delay9-1:$timePice8 + $delay8 + $animationBaseTimePart;
$delay9-2:$timePice8 + $delay8 + $animationBaseTimePart * 2;

$delay10-1:$timePice9 + $delay9 + $animationBaseTimePart;
$delay10-2:$timePice9 + $delay9 + $animationBaseTimePart * 2;

$delay11-1:$timePice10 + $delay10 + $animationBaseTimePart;
$delay11-2:$timePice10 + $delay10 + $animationBaseTimePart * 2;

$delay12-1:$timePice11 + $delay11 + $animationBaseTimePart;
$delay12-2:$timePice11 + $delay11 + $animationBaseTimePart * 2;

$delay13-1:$timePice12 + $delay12 + $animationBaseTimePart;
$delay13-2:$timePice12 + $delay12 + $animationBaseTimePart * 2;

$delay14-1:$timePice13 + $delay13 + $animationBaseTimePart;
$delay14-2:$timePice13 + $delay13 + $animationBaseTimePart * 2;

$delay15-1:$timePice14 + $delay14 + $animationBaseTimePart;
$delay15-2:$timePice14 + $delay14 + $animationBaseTimePart * 2;

$delay16-1:$timePice15 + $delay15 + $animationBaseTimePart;
$delay16-2:$timePice15 + $delay15 + $animationBaseTimePart * 2;